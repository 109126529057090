import { Button, ConfigProvider, DatePicker, Form, Input } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import ru_RU from 'antd/lib/locale-provider/ru_RU';
import { SelectValue } from 'antd/lib/select';
import { omit } from 'lodash';
import moment, { Moment } from 'moment';
import 'moment/locale/ru';
import React, { ComponentType, FunctionComponent, ReactNode, useCallback, useState } from 'react';
import CustomerSelect from '../../components/customerSelect/CustomerSelect';
import EmployeeSelect from '../../components/employeeSelect/EmployeeSelect';
import ProjectSelect from '../../components/projectSelect/ProjectSelect';
import { ISO_DATE_FORMAT } from '../../constants/dateFormat';
import { ANTD_COLS } from '../../constants/markup';
import useAuthSelector from '../../hooks/useAuthSelector';
import useFirstEffect from '../../hooks/useFirstEffect';
import Restricted, { DashUserRole } from '../../layout/restricted/Restricted';
import ImmutableMoment from '../../utils/ImmutableMoment';
import styles from './ReportByEmployeesFilter.module.css';
import { RangePickerValue } from 'antd/lib/date-picker/interface';

moment.locale('ru');

const { RangePicker } = DatePicker;
const startOfMonth = moment()
    .startOf('month')
    .format(ISO_DATE_FORMAT);
const today = moment().format(ISO_DATE_FORMAT);

const labelCol = 5;
const maxCols = ANTD_COLS - 7; //4

enum MonthSwitch {
    PREVIOUS = -1,
    NEXT = 1
}

interface Props {
    submitFilter(filterParams: object);

    adminSingleEmployeeFilter?: boolean;
    hideCustomersFilter?: boolean;
    exportNode?: ReactNode;
    setSelectedDates?: (dates: [Moment, Moment] | null) => void;

    onEmployeesChange?: (employees: string[] | string | undefined) => void;
}

type InternalProps = Props & FormComponentProps;

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

const getFieldsValue = (fieldsValue) => omit(fieldsValue, ['period']);

const ReportByEmployeesFilterForm: FunctionComponent<InternalProps> = (props) => {
    const {
        form,
        submitFilter,
        adminSingleEmployeeFilter,
        hideCustomersFilter,
        exportNode,
        onEmployeesChange,
        setSelectedDates
    } = props;
    const { user } = useAuthSelector();

    const [customersFilter, setCustomersFilter] = useState<string[]>();

    const setFormFieldValue = useCallback(
        (field: string, value) => {
            form.setFieldsValue({
                ...form.getFieldsValue(),
                [field]: value
            });
        },
        [form]
    );

    useFirstEffect(() => {
        form.validateFields((err, fieldsValue) => {
            if (err) {
                return;
            }

            const values = {
                ...getFieldsValue(fieldsValue),
                startDate: fieldsValue['period'][0].format(ISO_DATE_FORMAT),
                endDate: fieldsValue['period'][1].format(ISO_DATE_FORMAT)
            };

            submitFilter(values);
        });
    }, []);

    const handleDateChange = (dates: RangePickerValue, dateStrings: [string, string]) => {
        if (dates && dates[0] && dates[1] && setSelectedDates) {
            const startDate = moment(dateStrings[0], ISO_DATE_FORMAT);
            const endDate = moment(dateStrings[1], ISO_DATE_FORMAT);

            console.log('Start Date:', startDate);
            console.log('End Date:', endDate);

            setSelectedDates([startDate, endDate]);
        } else {
            console.log('Dates were cleared');
        }
    };

    const handleCustomersFilterChange = (value: SelectValue) => {
        if (Array.isArray(value)) {
            setCustomersFilter(value as string[]);
        }
    };

    const handleRangeMonthSwitch = (value: MonthSwitch): React.MouseEventHandler => () => {
        const currentDate = form.getFieldValue('period') as [Moment, Moment] | undefined;

        if (!setSelectedDates) {
            return;
        }

        if (!currentDate?.length || currentDate.some((date) => !Boolean(date))) {
            return;
        }

        const dateStart = currentDate[0];
        const dateEnd = currentDate[1];
        setSelectedDates([dateStart, dateEnd]);

        const isCurrentMonth = dateStart.month() < dateEnd.month();

        const baseDate = {
            [MonthSwitch.PREVIOUS]: dateStart,
            [MonthSwitch.NEXT]: dateEnd
        }[value];
        const newDateImmutable = new ImmutableMoment(baseDate).add('month', isCurrentMonth ? 0 : value);

        const newDateStart = newDateImmutable.set('date', 1).moment;

        setFormFieldValue('period', [newDateStart, newDateImmutable.set('date', newDateStart.daysInMonth()).moment]);
    };

    const handleSubmit = useCallback(
        (e) => {
            e.preventDefault();

            form.validateFields((err, fieldsValue) => {
                if (err) {
                    return;
                }

                const employeesField: string[] | string | undefined = fieldsValue['employees'];

                const employees = Array.isArray(employeesField) ? employeesField?.join() : employeesField;

                const values = {
                    ...getFieldsValue(fieldsValue),
                    projects: fieldsValue['projects']?.join(),
                    customers: fieldsValue['customers']?.join(),
                    employees,
                    startDate: fieldsValue['period'][0].format(ISO_DATE_FORMAT),
                    endDate: fieldsValue['period'][1].format(ISO_DATE_FORMAT)
                };

                submitFilter(values);
            });
        },
        [form, submitFilter]
    );

    const handleEmployeesChange = useCallback(
        (value) => {
            onEmployeesChange?.(value);
        },
        [onEmployeesChange]
    );

    const { getFieldDecorator, getFieldsError } = form;
    const formItemLayout = {
        labelCol: { span: labelCol },
        wrapperCol: { span: maxCols - labelCol }
    };
    const rangeConfig = {
        rules: [{ type: 'array', required: true, message: 'Укажите период' }],
        initialValue: [moment(startOfMonth, ISO_DATE_FORMAT), moment(today, ISO_DATE_FORMAT)]
    };

    const buttonItemLayout = {
        wrapperCol: { span: maxCols - labelCol, offset: labelCol }
    };

    return (
        <Form {...formItemLayout} className={styles.form} onSubmit={handleSubmit}>
            <Form.Item label="Период">
                <Input.Group compact>
                    <ConfigProvider locale={ru_RU}>
                        {getFieldDecorator(
                            'period',
                            rangeConfig
                        )(<RangePicker className={styles.datePicker} onChange={handleDateChange} />)}
                    </ConfigProvider>
                    <Button.Group>
                        <Button
                            icon="left"
                            className={styles.datePickerButton}
                            onClick={handleRangeMonthSwitch(MonthSwitch.PREVIOUS)}
                        />
                        <Button
                            icon="right"
                            className={styles.datePickerButton}
                            onClick={handleRangeMonthSwitch(MonthSwitch.NEXT)}
                        />
                    </Button.Group>
                </Input.Group>
            </Form.Item>
            {hideCustomersFilter ? null : (
                <Restricted roles={DashUserRole.ADMIN}>
                    <Form.Item label="Заказчики">
                        {getFieldDecorator('customers')(
                            <CustomerSelect
                                placeholder=""
                                allowClear
                                mode="multiple"
                                onChange={handleCustomersFilterChange}
                            />
                        )}
                    </Form.Item>
                </Restricted>
            )}
            <Form.Item label="Проекты">
                {getFieldDecorator('projects')(
                    <ProjectSelect placeholder="" allowClear mode="multiple" customersFilter={customersFilter} />
                )}
            </Form.Item>
            <Restricted roles={adminSingleEmployeeFilter ? DashUserRole.ADMIN : undefined}>
                <Form.Item label={adminSingleEmployeeFilter ? 'Пользователь' : 'Сотрудники'}>
                    {getFieldDecorator('employees')(
                        <EmployeeSelect
                            showSearch={adminSingleEmployeeFilter || undefined}
                            mode={adminSingleEmployeeFilter ? 'default' : 'multiple'}
                            allowClear
                            onChange={handleEmployeesChange}
                            placeholder={adminSingleEmployeeFilter ? user?.id : undefined}
                        />
                    )}
                </Form.Item>
            </Restricted>

            <Form.Item {...buttonItemLayout}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>
                        Построить отчет
                    </Button>
                    {exportNode}
                </div>
            </Form.Item>
        </Form>
    );
};

const ReportByEmployeesFilter = Form.create<InternalProps>({ name: 'filter' })(ReportByEmployeesFilterForm);

export default (ReportByEmployeesFilter as unknown) as ComponentType<Props>;
