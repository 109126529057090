import { DownloadReportParams } from './downloadWorkLogsReport';
import SmartURLSearchParams from '../../../utils/smartURLSearchParams';

const downloadExternalIdReport = async (params: DownloadReportParams) => {
    const queryParams = new SmartURLSearchParams({
        startDate: params.startDate,
        endDate: params.endDate,
        customers: params.customers?.join(',') ?? undefined,
        projects: params.projects?.join(',') ?? undefined,
        user: params.user ?? undefined
    });

    try {
        window.open(`/api/report/excel/external-ids?${new URLSearchParams(queryParams).toString()}`, '_blank');
    } catch (error) {
        console.error('Ошибка при скачивании отчета:', error);
    }
};

export default downloadExternalIdReport;
