import { DatePicker, Form, Icon, Input } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import createUserWorkLog from '../../../../actions/api/worklogs/createUserWorkLog';
import deleteUserWorkLog from '../../../../actions/api/worklogs/deleteUserWorkLog';
import editUserWorkLog from '../../../../actions/api/worklogs/editUserWorkLog';
import ProjectSelect from '../../../../components/projectSelect/ProjectSelect';
import { ANTD_COLS } from '../../../../constants/markup';
import { useAppDispatch } from '../../../../hooks/reactRedux';
import getErrorMessage from '../../../../utils/getErrorMessage';
import EditFormState from '../../types/EditFormState';
import EditMode from '../../types/EditMode';
import confirmWorkLogDelete from '../../utils/confirmWorkLogDelete';
import validateWorkLogDate from '../../utils/validateWorkLogDate';
import styles from './ActivityEditForm.module.css';
import EditingMenu from './components/editingMenu/EditingMenu';
import ActivityEditFormObject, { createUserWorkLogEditFromFormObject } from './types/ActivityEditFormObject';
import { isNil, isNull } from 'lodash';

const DATE_RANGE_ERROR = 'Допустимы списания только за текущую неделю';

const formHeadingsByMode = {
    [EditMode.ADD]: 'Добавление списания',
    [EditMode.EDIT]: 'Редактирование списания'
} as const;

const labelCol = 5;

interface Props {
    editFormState: EditFormState;
    setEditFormState: React.Dispatch<React.SetStateAction<EditFormState>>;
    /** Пользователь, от лица которого совершаются действия. Доступно администраторам */
    adminUser?: string;
}

interface InternalProps extends Props {
    form: WrappedFormUtils;
}

const ActivityEditForm: FunctionComponent<InternalProps> = ({ editFormState, setEditFormState, form, adminUser }) => {
    const dispatch = useAppDispatch();

    const menuColOffset = editFormState.mode === EditMode.NONE ? 0 : 5;
    const { getFieldDecorator, getFieldsValue, setFieldsValue, validateFields } = form;

    const [isSavingLoading, setIsSavingLoading] = useState<boolean>(false);
    const [isDeletingLoading, setIsDeletingLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();

    const previousEditFormStateRef = useRef<EditFormState>();

    useEffect(() => {
        if (previousEditFormStateRef.current === editFormState) {
            previousEditFormStateRef.current = editFormState;
            return;
        }

        if (editFormState.mode === EditMode.EDIT && editFormState.editingItem?.editedObject) {
            if (previousEditFormStateRef.current?.mode === EditMode.ADD) {
                const fieldsValue = getFieldsValue() as ActivityEditFormObject;
                setEditFormState((state) => ({
                    ...state,
                    addingItem: fieldsValue
                }));
            }

            const editedObject = editFormState.editingItem.editedObject;

            setFieldsValue({
                ...editedObject,
                externalId: editedObject.externalId
            });
        }

        if (editFormState.mode === EditMode.ADD && editFormState.addingItem) {
            setFieldsValue(editFormState.addingItem);
        }

        previousEditFormStateRef.current = editFormState;
    }, [editFormState, getFieldsValue, setEditFormState, setFieldsValue]);

    const handleAddActivityClick = useCallback(() => {
        setEditFormState((state) => ({
            ...state,
            mode: EditMode.ADD,
            addingItem: {
                comment: '',
                date: moment()
            }
        }));
    }, [setEditFormState]);

    const returnToPreviousEditMode = useCallback(() => {
        setEditFormState((state) => {
            if (state.mode === EditMode.EDIT && state.addingItem) {
                return {
                    ...state,
                    mode: EditMode.ADD,
                    editingItem: undefined
                };
            }

            return {
                ...state,
                mode: EditMode.NONE,
                editingItem: undefined,
                addingItem: undefined
            };
        });
    }, [setEditFormState]);

    const deleteEditingItem = useCallback(async () => {
        if (editFormState.mode !== EditMode.EDIT) {
            throw new Error('handleDeleteClick: mode is not EDIT');
        }
        if (!editFormState.editingItem) {
            throw new Error('handleDeleteClick: no editing item');
        }

        setIsDeletingLoading(true);
        setError(undefined);
        try {
            await dispatch(deleteUserWorkLog(editFormState.editingItem.originalObject.issueId, adminUser));

            returnToPreviousEditMode();
        } catch (error) {
            setError(getErrorMessage(error));
        } finally {
            setIsDeletingLoading(false);
        }
    }, [adminUser, dispatch, editFormState.editingItem, editFormState.mode, returnToPreviousEditMode]);

    const handleDeleteClick = useCallback(() => {
        confirmWorkLogDelete(deleteEditingItem);
    }, [deleteEditingItem]);

    const handleFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        validateFields(async (errors, values) => {
            if (errors) {
                //Если единственная ошибка - DATE_RANGE_ERROR, игнорируем
                if (
                    !(
                        Object.keys(errors).length === 1 &&
                        errors.date?.errors?.length === 1 &&
                        errors.date.errors[0]?.message === DATE_RANGE_ERROR
                    )
                ) {
                    return;
                }
            }

            const userWorkLogEdit = createUserWorkLogEditFromFormObject(values);

            setIsSavingLoading(true);
            setError(undefined);
            try {
                if (editFormState.mode === EditMode.ADD) {
                    await dispatch(createUserWorkLog(userWorkLogEdit, adminUser));
                } else if (editFormState.mode === EditMode.EDIT && editFormState.editingItem) {
                    await dispatch(
                        editUserWorkLog(userWorkLogEdit, editFormState.editingItem.originalObject.issueId, adminUser)
                    );
                }

                returnToPreviousEditMode();
            } catch (error) {
                setError(getErrorMessage(error));
            } finally {
                setIsSavingLoading(false);
            }
        });
    };

    return (
        <Form
            className={styles.form}
            labelCol={{ span: labelCol }}
            wrapperCol={{ span: ANTD_COLS - labelCol }}
            onSubmit={handleFormSubmit}
        >
            {editFormState.mode !== EditMode.NONE && (
                <>
                    <Form.Item wrapperCol={{ span: ANTD_COLS - labelCol, offset: labelCol }}>
                        <h2>{formHeadingsByMode[editFormState.mode]}</h2>
                    </Form.Item>
                    {editFormState.mode === EditMode.EDIT && (
                        <Form.Item label="ID">
                            {getFieldDecorator('issueId')(
                                <Input type="text" prefix={<Icon type="idcard" />} placeholder="ID" readOnly />
                            )}
                        </Form.Item>
                    )}
                    <Form.Item label="Дата">
                        {getFieldDecorator('date', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Выберите дату'
                                },
                                {
                                    validator: (rule, value, callback) => {
                                        const isValid = validateWorkLogDate(value);
                                        // const hasApprovalToken = Boolean(getFieldValue('approvalToken')?.length);

                                        const error = isValid // || hasApprovalToken
                                            ? undefined
                                            : DATE_RANGE_ERROR;
                                        callback(error);
                                    }
                                }
                            ]
                        })(<DatePicker placeholder="Дата" className="date-picker-w100" />)}
                    </Form.Item>
                    <Form.Item label="Проект">
                        {getFieldDecorator('project', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Выберите проект'
                                }
                            ]
                        })(<ProjectSelect placeholder="Проект" internal showSearch />)}
                    </Form.Item>
                    <Form.Item label="Время">
                        {getFieldDecorator('amount', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Укажите затраченное время'
                                },
                                {
                                    validator: (rule, value, callback) => {
                                        const numericValue = Number(value);
                                        const isPositive = numericValue > 0;
                                        const isMultipleOf05 = numericValue % 0.5 === 0;
                                        const isValid = !value || (isPositive && isMultipleOf05);
                                        const error = isValid ? undefined : 'Время должно быть больше 0 и кратно 0.5';
                                        callback(error);
                                    }
                                }
                            ]
                        })(
                            <Input
                                type="number"
                                prefix={<Icon type="clock-circle" />}
                                placeholder="Затраченное время в часах"
                            />
                        )}
                    </Form.Item>
                    <Form.Item label="Внешний ID">
                        {getFieldDecorator('externalId', {
                            rules: [
                                {
                                    required: false
                                }
                            ]
                        })(<Input type="text" placeholder="Внешний ID" />)}
                    </Form.Item>
                    <Form.Item label="Комментарий">
                        {getFieldDecorator('comment', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Введите комментарий'
                                },
                                {
                                    min: 10,
                                    message: 'Длина комментария должна быть не менее 10 символов'
                                }
                            ]
                        })(<TextArea placeholder="Комментарий" rows={3} />)}
                    </Form.Item>
                    {/*<Form.Item label="Токен согласования">*/}
                    {/*    {getFieldDecorator('approvalToken')(<Input type="text" placeholder="Токен согласования" />)}*/}
                    {/*</Form.Item>*/}

                    {error && (
                        <Form.Item
                            wrapperCol={{ span: ANTD_COLS - menuColOffset, offset: menuColOffset }}
                            className={styles.error}
                        >
                            <Text type="danger">{error}</Text>
                        </Form.Item>
                    )}
                </>
            )}
            <Form.Item wrapperCol={{ span: ANTD_COLS - menuColOffset, offset: menuColOffset }}>
                <EditingMenu
                    editMode={editFormState.mode}
                    isSavingLoading={isSavingLoading}
                    isDeletingLoading={isDeletingLoading}
                    onAddActivityClick={handleAddActivityClick}
                    onCancelClick={returnToPreviousEditMode}
                    onDeleteClick={handleDeleteClick}
                />
            </Form.Item>
        </Form>
    );
};

export default (Form.create()(ActivityEditForm) as unknown) as FunctionComponent<Props>;
