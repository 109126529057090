import { Button, Select } from 'antd';
import moment, { Moment } from 'moment';
import React, { FunctionComponent, useState } from 'react';
import downloadExternalIdReport from '../../actions/api/reports/downloadExternalIdReport';
import downloadWorkLogsReport from '../../actions/api/reports/downloadWorkLogsReport';
import { ISO_DATE_FORMAT } from '../../constants/dateFormat';
import { UserWorkLog, UserWorkLogsReport } from '../../types/apiDomain';
import { minutesToHours } from '../../utils/timeConversion';
import XlsReportTemplate, { RenderCells, RenderColumns, Renderers } from '../xlsReportTemplate/XlsReportTemplate';
import styles from './WorklogsXlsExport.module.scss';

moment.locale('ru');

const { Option } = Select;

const renderHeadCells: RenderColumns<UserWorkLog> = {
    issueId: 'Issue',
    isInternal: 'Внутреннее',
    date: 'Дата',
    project: 'Проект',
    minutes: 'Время',
    comment: 'Комментарий'
};

const renderCells: RenderCells<UserWorkLog> = {
    issueId: (value, { issueUrl }) => <a href={issueUrl ?? undefined}>{value}</a>,
    isInternal: (value) => (value ? 'Да' : ''),
    minutes: (value) => Renderers.renderNumber(minutesToHours(value))
};

interface Props {
    workLogsReport?: UserWorkLogsReport;
    selectedDates: [Moment, Moment] | null;
}

enum ExportFormat {
    WORKLOG = 'worklog',
    EXTERNAL_ID = 'externalId'
}

const WorklogsXlsExport: FunctionComponent<Props> = ({ workLogsReport, selectedDates }) => {
    const [exportFormat, setExportFormat] = useState<ExportFormat>(ExportFormat.WORKLOG);

    const handleExport = () => {
        if (!selectedDates) {
            console.error('No dates selected');
            return;
        }

        const [startDate, endDate] = selectedDates;

        const formattedStartDate = startDate.format(ISO_DATE_FORMAT);
        const formattedEndDate = endDate.format(ISO_DATE_FORMAT);

        switch (exportFormat) {
            case ExportFormat.WORKLOG:
                downloadWorkLogsReport({ startDate: formattedStartDate, endDate: formattedEndDate });
                break;
            case ExportFormat.EXTERNAL_ID:
                downloadExternalIdReport({ startDate: formattedStartDate, endDate: formattedEndDate });
                break;
            default:
                throw new Error(`Unknown exportFormat (${exportFormat})`);
        }
    };

    return (
        <div className={styles.buttons}>
            <XlsReportTemplate
                data={workLogsReport?.workLogs ?? []}
                filename="worklogs"
                renderColumns={renderHeadCells}
                renderCells={renderCells}
            />

            <Select
                defaultValue="worklog"
                style={{ width: 200, margin: '0 12px' }}
                onChange={(value) => setExportFormat(value as ExportFormat)}
            >
                <Option value={ExportFormat.WORKLOG}>Отчет Worklog</Option>
                <Option value={ExportFormat.EXTERNAL_ID}>Отчет с внешним Id</Option>
            </Select>

            <Button type="primary" onClick={handleExport}>
                Скачать
            </Button>
        </div>
    );
};

export default WorklogsXlsExport;
