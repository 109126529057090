import SmartURLSearchParams from '../../../utils/smartURLSearchParams';

export interface DownloadReportParams {
    startDate: string;
    endDate: string;
    customers?: string[];
    projects?: string[];
    user?: string;
}

const downloadWorkLogsReport = async (params: DownloadReportParams) => {
    const queryParams = new SmartURLSearchParams({
        startDate: params.startDate,
        endDate: params.endDate,
        customers: params.customers?.join(',') ?? undefined,
        projects: params.projects?.join(',') ?? undefined,
        user: params.user ?? undefined
    });

    try {
        window.open(`/api/report/excel/worklogs?${new URLSearchParams(queryParams).toString()}`, '_blank');
    } catch (error) {
        console.error('Ошибка при скачивании отчета:', error);
    }
};

export default downloadWorkLogsReport;
